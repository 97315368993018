.not-found {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 999;
    background: url('../images/404.gif');
    background-size: cover;
    background-position: 0 50%;
    background-repeat: no-repeat;
    text-align: center;
    padding: 30px;
    .logo {
        margin:100px 0 0;
    }
    .page-title {
        h1 {
            margin:20px 0 20px;
            font-size: 40px;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
    .banner {
        display: block;
        max-width: 500px;
        font-size: 170px;
        font-weight: bold;
        margin: 0 auto;
        background: #1A161C;
        height: 220px;
        line-height: 1;
        padding: 25px 50px;
    }
    .button {
        display: block;
        max-width: 500px;
        height: 130px;
        background:url('../images/cta.svg');
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
        margin:30px auto; 
    }
}