/* Set inter font */
@font-face {
  font-family: 'inter';
  src: url("../fonts/inter/Inter-Regular.woff");
  src: url("../fonts/inter/Inter-Regular.woff2");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'inter';
  src: url("../fonts/inter/Inter-Medium.woff");
  src: url("../fonts/inter/Inter-Medium.woff2");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'inter';
  src: url("../fonts/inter/Inter-Bold.woff");
  src: url("../fonts/inter/Inter-Bold.woff2");
  font-style: normal;
  font-weight: 800; }

/* Set icon font */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons-font/icomoon.eot");
  src: url("../fonts/icons-font/icomoon.eot") format("embedded-opentype"), url("../fonts/icons-font/icomoon.ttf") format("truetype"), url("../fonts/icons-font/icomoon.woff") format("woff"), url("../fonts/icons-font/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-itunes:before {
  content: "\e90e"; }

.icon-vlc:before {
  content: "\e90f"; }

.icon-google-home:before {
  content: "\e910"; }

.icon-headset:before {
  content: "\e900"; }

.icon-alexa:before {
  content: "\e901"; }

.icon-android:before {
  content: "\e902"; }

.icon-apple:before {
  content: "\e903"; }

.icon-addict-radio:before {
  content: "\e904"; }

.icon-favourite:before {
  content: "\e905"; }

.icon-favourite-empty:before {
  content: "\e906"; }

.icon-mail:before {
  content: "\e907"; }

.icon-pause:before {
  content: "\e908"; }

.icon-play:before {
  content: "\e909"; }

.icon-share:before {
  content: "\e90a"; }

.icon-facebook:before {
  content: "\e90b"; }

.icon-twitter:before {
  content: "\e90c"; }

.icon-reload:before {
  content: "\e90d"; }

.icon-winamp {
  display: block;
  height: 40px;
  background: url("../images/winamp.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.not-found {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: url("../images/404.gif");
  background-size: cover;
  background-position: 0 50%;
  background-repeat: no-repeat;
  text-align: center;
  padding: 30px; }
  .not-found .logo {
    margin: 100px 0 0; }
  .not-found .page-title h1 {
    margin: 20px 0 20px;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase; }
  .not-found .banner {
    display: block;
    max-width: 500px;
    font-size: 170px;
    font-weight: bold;
    margin: 0 auto;
    background: #1A161C;
    height: 220px;
    line-height: 1;
    padding: 25px 50px; }
  .not-found .button {
    display: block;
    max-width: 500px;
    height: 130px;
    background: url("../images/cta.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 30px auto; }

@media screen and (min-width: 769px) and (max-height: 768px) {
  .addict-radio .page-title h1 {
    margin-top: 20px;
    font-size: 34px; }
  .addict-radio .radio-stations {
    margin-top: 80px; }
  div.devices h2 {
    margin: 80px 0 50px; } }

@media screen and (min-width: 769px) and (min-height: 769px) and (max-height: 800px) {
  .addict-radio .page-title h1 {
    margin-top: 20px; }
  .addict-radio .radio-stations {
    margin-top: 80px; } }

@media screen and (min-width: 769px) and (min-height: 801px) and (max-height: 900px) {
  .addict-radio .page-title h1 {
    margin-top: 50px; } }

@media screen and (max-width: 1240px) {
  .addict-radio .radio-stations {
    justify-content: center; }
    .addict-radio .radio-stations .radio-station {
      margin: 0 50px 100px; } }

@media screen and (max-width: 768px) {
  .addict-radio .header {
    padding: 30px; }
  .addict-radio .logo {
    width: 140px;
    height: 30px; }
  .addict-radio .page-title h1 {
    font-size: 32px;
    line-height: 45px; }
  .not-found {
    background-position: 50% 50%; } }

@media screen and (min-width: 601px) and (max-width: 769px) {
  .addict-radio .page-title h1 {
    margin-top: 50px; }
  .addict-radio .radio-stations {
    margin-top: 100px; } }

@media screen and (max-width: 600px) {
  .addict-radio .header .link {
    font-size: 0; }
    .addict-radio .header .link i {
      font-size: 18px;
      margin-right: 0; }
  .addict-radio .header > div a:first-child {
    margin-right: 20px; }
  .addict-radio .page-title h1 {
    margin-top: 0;
    font-size: 28px; }
  .addict-radio .page-title p {
    font-size: 18px;
    margin: 20px 0 0;
    line-height: 26px; }
  .addict-radio .radio-stations {
    margin-top: 50px; }
    .addict-radio .radio-stations .radio-station {
      margin: 0 0 50px;
      width: 260px; }
      .addict-radio .radio-stations .radio-station h3 {
        margin-bottom: 20px; }
      .addict-radio .radio-stations .radio-station .cover {
        border-radius: 10px;
        height: 340px; }
        .addict-radio .radio-stations .radio-station .cover .song-image {
          width: 140px;
          height: 140px; }
          .addict-radio .radio-stations .radio-station .cover .song-image .image p {
            font-size: 13px;
            margin: 4px 0;
            line-height: 19px; }
      .addict-radio .radio-stations .radio-station .player {
        width: 180px;
        height: 70px;
        padding: 10px 20px; }
        .addict-radio .radio-stations .radio-station .player .control {
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 50%; }
  .addict-radio .flex-row {
    display: block; }
  .addict-radio .devices h2 {
    margin: 80px 0 50px; }
  .addict-radio .devices .app-link {
    margin: 0 auto 30px;
    display: block; }
    .addict-radio .devices .app-link:last-child {
      margin-bottom: 0; }
  .not-found {
    padding: 30px 20px; }
    .not-found .banner {
      font-size: 75px;
      height: auto; } }

@media screen and (min-width: 1200px) and (max-height: 769px) {
  div.radio-stations .radio-station {
    width: 250px; }
    div.radio-stations .radio-station .cover {
      height: 340px; }
      div.radio-stations .radio-station .cover .song-image {
        width: 160px;
        height: 160px; }
        div.radio-stations .radio-station .cover .song-image .image p {
          font-size: 15px;
          line-height: 20px;
          margin: 10px 0; }
    div.radio-stations .radio-station .player-effect {
      width: 250px;
      height: 250px; }
    div.radio-stations .radio-station .player {
      height: 90px;
      padding: 10px 20px; } }

* {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

html {
  margin: 0;
  padding: 0; }
  html body {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: "inter", sans-serif;
    background: #0E0F11;
    font-size: 16px; }
    html body img {
      display: block;
      max-width: 100%;
      max-height: 100%; }
    html body a {
      text-decoration: none; }
      html body a.link {
        text-decoration: none;
        color: inherit;
        font-size: inherit;
        transition: opacity .2s ease;
        font-size: 14px;
        font-weight: 500; }
        html body a.link:focus, html body a.link:active, html body a.link:hover {
          opacity: .7; }
        html body a.link i {
          font-size: 12px;
          margin-right: 5px; }

.text-center {
  text-align: center; }

.logo {
  display: inline-block;
  width: 180px;
  height: 40px; }
  .logo img {
    width: 100%; }

.header {
  max-width: 1660px;
  padding: 30px 30px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; }
  .header > div a:first-child {
    margin-right: 30px; }

.page-title h1 {
  font-size: 40px;
  margin: 110px auto 0;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 30px; }
  .page-title h1 span {
    background: #fff;
    padding: 1px 6px;
    color: #0E0F11; }

.page-title p {
  font-size: 24px;
  text-align: center;
  margin: 40px 0 0;
  padding: 0 30px; }

.addict-radio {
  overflow: hidden; }

.radio-stations {
  max-width: 1500px;
  margin: 110px auto 0;
  padding: 0 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .radio-stations .radio-station {
    width: 275px;
    text-align: center;
    position: relative;
    margin: 0 0 100px; }
    .radio-stations .radio-station .player-effect {
      display: block;
      width: 275px;
      height: 275px;
      position: absolute;
      top: 25%;
      left: 0;
      filter: blur(25px);
      border-radius: 50%;
      animation: animate 2s linear infinite; }

@keyframes animate {
  0% {
    transform: rotate(0deg) scale(1); }
  50% {
    transform: rotate(180deg) scale(1.1); }
  100% {
    transform: rotate(360deg) scale(1); } }
    .radio-stations .radio-station h3 {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 0 25px; }
      .radio-stations .radio-station h3 i {
        width: 30px;
        height: 30px;
        background: #fff;
        color: #0E0F11;
        border-radius: 50%;
        display: inline-block;
        font-size: 13px;
        line-height: 30px;
        text-align: center;
        margin-right: 15px; }
    .radio-stations .radio-station .cover {
      position: relative;
      overflow: hidden;
      border-radius: 27px;
      height: 405px;
      perspective: 100px;
      background-color: #0E0F11; }
      .radio-stations .radio-station .cover.no-artist-image:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 2s; }
      .radio-stations .radio-station .cover:hover:after {
        transform: scale(1.1) rotate(-5deg); }
      .radio-stations .radio-station .cover h2, .radio-stations .radio-station .cover p {
        position: relative;
        z-index: 4;
        color: #fff;
        opacity: 0;
        padding: 0 20px;
        overflow: hidden; }
      .radio-stations .radio-station .cover h2 {
        font-size: 18px;
        text-transform: uppercase;
        margin: 30px 0 5px;
        height: 22px; }
      .radio-stations .radio-station .cover p {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 10px;
        height: 45px; }
      .radio-stations .radio-station .cover .gradient-overlay,
      .radio-stations .radio-station .cover .no-info-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .radio-stations .radio-station .cover .gradient-overlay {
        z-index: 3; }
      .radio-stations .radio-station .cover .no-info-background {
        z-index: 4;
        padding: 20px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .radio-stations .radio-station .cover .no-info-background h3 {
          font-size: 24px;
          font-weight: bold;
          margin: 0 0 5px;
          display: block;
          width: 100%; }
        .radio-stations .radio-station .cover .no-info-background span {
          font-size: 22px;
          display: block;
          width: 100%; }
      .radio-stations .radio-station .cover .radio-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
        font-size: 100px;
        z-index: 2;
        color: #fff; }
      .radio-stations .radio-station .cover .song-image {
        display: inline-block;
        width: 200px;
        height: 200px;
        z-index: 3;
        position: relative;
        opacity: 0;
        transition: transform .15s, opacity .1s;
        transform-origin: 50% 50%;
        transform: scale(0.8); }
        .radio-stations .radio-station .cover .song-image .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: #fff; }
          .radio-stations .radio-station .cover .song-image .image p {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 400;
            margin: 20px 0;
            height: auto;
            padding: 0 10px;
            line-height: 23px; }
        .radio-stations .radio-station .cover .song-image:after {
          content: '';
          position: absolute;
          left: 5%;
          width: 90%;
          height: 80%;
          top: 20%;
          z-index: 0;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.32); }
    .radio-stations .radio-station .player {
      width: 210px;
      height: 100px;
      color: #000;
      border-radius: 15px;
      background: #fff;
      margin: -50px auto 0;
      position: relative;
      z-index: 5;
      padding: 15px 20px; }
      .radio-stations .radio-station .player i {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer; }
      .radio-stations .radio-station .player .control {
        width: 50px;
        height: 70px;
        font-size: 14px;
        line-height: 70px;
        border: 1px solid #000;
        border-radius: 25px;
        margin: 0 20px;
        transition: border-color .2s, background .2s; }
      .radio-stations .radio-station .player .share {
        display: inline-block;
        position: relative;
        width: 15px;
        height: 16px; }
        .radio-stations .radio-station .player .share .icon-share {
          position: relative;
          z-index: 1;
          transition: color .2s ease; }
        .radio-stations .radio-station .player .share .wrapper {
          position: absolute;
          top: -8px;
          width: 106px;
          left: -12px;
          height: 38px;
          background: #EDEAEA;
          transition: transform .2s, opacity .1s ease;
          border-radius: 25px;
          opacity: 0;
          padding: 4px 0 3px 35px;
          text-align: left;
          transform: scale(0); }
          .radio-stations .radio-station .player .share .wrapper i {
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 5px 0 0;
            overflow: hidden;
            line-height: 30px;
            background: transparent;
            text-align: center;
            transition: background .2s ease;
            font-size: 15px; }
            .radio-stations .radio-station .player .share .wrapper i:hover {
              background: #fff; }
        .radio-stations .radio-station .player .share.active .wrapper {
          opacity: 1;
          transform: scale(1); }
        .radio-stations .radio-station .player .share.active .icon-share {
          color: #9B9B9B; }
    .radio-stations .radio-station.is-playing .line {
      top: -70%;
      height: 190%; }
    .radio-stations .radio-station.is-playing .cover .song-image {
      transform: scale(1);
      opacity: 1; }
    .radio-stations .radio-station.is-playing .cover h2, .radio-stations .radio-station.is-playing .cover p {
      opacity: 1; }
    .radio-stations .radio-station.is-playing .cover:after {
      opacity: 0; }
    .radio-stations .radio-station.alternative .cover:after {
      background: url("../images/alternative.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .radio-stations .radio-station.alternative .player-effect {
      background: linear-gradient(#61a9d2, #447591, #9edcff); }
    .radio-stations .radio-station.rock .cover:after {
      background: url("../images/rock.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .radio-stations .radio-station.rock .player-effect {
      background: linear-gradient(#e55050, #923434, #ffa4a4); }
    .radio-stations .radio-station.star .cover:after {
      background: url("../images/star.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .radio-stations .radio-station.star .player-effect {
      background: linear-gradient(#ef87ce, #a35e8d, #ffafe6); }
    .radio-stations .radio-station.lounge .cover:after {
      background: url("../images/lounge.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .radio-stations .radio-station.lounge .player-effect {
      background: linear-gradient(#8d84e6, #6660a7, #bfb9ff); }
    .radio-stations .radio-station.error {
      display: block;
      margin: 0 auto; }
      .radio-stations .radio-station.error .no-info-background {
        z-index: 3; }

.container-l {
  display: block;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 30px; }

.container-s {
  display: block;
  max-width: 760px;
  margin: 0 auto;
  padding: 0 30px; }

.container-xs {
  display: block;
  max-width: 530px;
  margin: 0 auto;
  padding: 0 30px; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.how-to-listen {
  padding-bottom: 50px; }

.privacy-policy a {
  text-decoration: underline;
  color: white; }
  .privacy-policy a.link {
    text-decoration: underline;
    color: white;
    font-size: inherit;
    transition: opacity .2s ease;
    font-size: 14px;
    font-weight: 500; }
    .privacy-policy a.link:focus, .privacy-policy a.link:active, .privacy-policy a.link:hover {
      opacity: .7; }
    .privacy-policy a.link i {
      font-size: 12px;
      margin-right: 5px; }

.ghack1 {
  padding: 50px 0 0 0; }

.devices h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 100px 0 50px; }

.devices .app-link {
  width: 120px;
  text-decoration: none;
  color: #fff;
  transition: opacity .2s ease; }
  .devices .app-link i {
    display: block;
    font-size: 40px;
    margin: 0 0 10px; }
  .devices .app-link span {
    text-decoration: underline;
    font-size: 15px; }
  .devices .app-link:hover {
    opacity: .7; }
    .devices .app-link:hover span {
      text-decoration: none; }
