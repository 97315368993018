@media screen and (min-width: 769px) and (max-height: 768px) {
    .addict-radio {
        .page-title {
            h1 {
                margin-top: 20px;
                font-size: 34px;
            }
        }
        .radio-stations {
            margin-top:80px;
        }
    }   
    div.devices h2 {
        margin: 80px 0 50px;
    }
}
@media screen and (min-width: 769px) and (min-height: 769px) and (max-height:800px) {
    .addict-radio {
        .page-title {
            h1 {
                margin-top: 20px;
            }
        }
        .radio-stations {
            margin-top:80px;
        }
    }   
}
@media screen and (min-width: 769px) and (min-height: 801px) and (max-height:900px) {
    .addict-radio {
        .page-title {
            h1 {
                margin-top: 50px;
            }
        }
    }   
}
@media screen and (max-width: 1240px){
    .addict-radio {
        .radio-stations {
            justify-content: center;
            .radio-station {
                margin:0 50px 100px;
            }
        }
    }   
}
@media screen and (max-width: 768px){
    .addict-radio {
        .header {
            padding: 30px;
        }
        .logo {
            width:140px;
            height: 30px;
        }
        .page-title {
            h1 {
                font-size: 32px;
                line-height: 45px;
            }
        }
    }
    .not-found {
        background-position: 50% 50%;
    }  
}
@media screen and (min-width: 601px) and (max-width: 769px){
    .addict-radio {
        .page-title {
            h1 {
                margin-top: 50px;
            }
        }
        .radio-stations {
            margin-top: 100px;
        }
    }  
}
@media screen and (max-width: 600px){
    .addict-radio {
        .header {
            .link {
                font-size:0;
                i {
                    font-size: 18px;
                    margin-right: 0;
                }
            }
            > div a:first-child {margin-right: 20px;}
        }
        .page-title {
            h1 {
                margin-top: 0;
                font-size: 28px;   
            }
            p {
                font-size: 18px;
                margin: 20px 0 0;
                line-height: 26px;
            }
        }
        .radio-stations {
            margin-top: 50px;
            .radio-station {
                margin: 0 0 50px;
                width: 260px;
                h3 {
                    margin-bottom: 20px;
                }
                .cover {
                    border-radius: 10px;
                    height: 340px;
                    .song-image {
                        width:140px;
                        height:140px;
                        .image {
                            p {
                                font-size: 13px;
                                margin: 4px 0;
                                line-height: 19px;
                            }
                        }
                    }
                }
                .player {
                    width: 180px;
                    height: 70px;
                    padding: 10px 20px;
                    .control {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        border-radius: 50%;
                    }
                }
            }
        }
        .flex-row {
            display: block;
        }
        .devices {
            h2 {
                margin: 80px 0 50px;
            }
            .app-link {
                margin: 0 auto 30px;
                display: block;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        } 
    }  
    .not-found {
        padding: 30px 20px;
        .banner {
            font-size: 75px;
            height: auto;
        }
    }

}

@media screen and (min-width:1200px) and (max-height: 769px){
    div.radio-stations {
        .radio-station {
            width: 250px;
            .cover {
                height: 340px;
                .song-image {
                    width: 160px;
                    height: 160px;
                    .image p {
                        font-size: 15px;
                        line-height: 20px;
                        margin: 10px 0;
                    }
                }
            }
            .player-effect {
                width: 250px;
                height: 250px;
            }
            .player {
                height: 90px;
                padding: 10px 20px;
            }
        }
    }
}
