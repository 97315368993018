/* Set inter font */
@font-face {
  font-family: 'inter';
  src:  url('../fonts/inter/Inter-Regular.woff');
  src:  url('../fonts/inter/Inter-Regular.woff2');
  font-style:  normal;
  font-weight: 400;
}
@font-face {
  font-family: 'inter';
  src:  url('../fonts/inter/Inter-Medium.woff');
  src:  url('../fonts/inter/Inter-Medium.woff2');
  font-style:  normal;
  font-weight: 500;
}
@font-face {
  font-family: 'inter';
  src:  url('../fonts/inter/Inter-Bold.woff');
  src:  url('../fonts/inter/Inter-Bold.woff2');
  font-style:  normal;
  font-weight: 800;
}

/* Set icon font */
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icons-font/icomoon.eot');
    src:  url('../fonts/icons-font/icomoon.eot') format('embedded-opentype'),
      url('../fonts/icons-font/icomoon.ttf') format('truetype'),
      url('../fonts/icons-font/icomoon.woff') format('woff'),
      url('../fonts/icons-font/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-itunes:before {
    content: "\e90e";
  }
  .icon-vlc:before {
    content: "\e90f";
  }
  .icon-google-home:before {
    content: "\e910";
  }
  .icon-headset:before {
    content: "\e900";
  }
  .icon-alexa:before {
    content: "\e901";
  }
  .icon-android:before {
    content: "\e902";
  }
  .icon-apple:before {
    content: "\e903";
  }
  .icon-addict-radio:before {
    content: "\e904";
  }
  .icon-favourite:before {
    content: "\e905";
  }
  .icon-favourite-empty:before {
    content: "\e906";
  }
  .icon-mail:before {
    content: "\e907";
  }
  .icon-pause:before {
    content: "\e908";
  }
  .icon-play:before {
    content: "\e909";
  }
  .icon-share:before {
    content: "\e90a";
  }
  .icon-facebook:before {
    content: "\e90b";
  }
  .icon-twitter:before {
    content: "\e90c";
  }
  .icon-reload:before {
    content: "\e90d";
  }
  .icon-winamp {
    display: block;
    height: 40px;
    background: url('../images/winamp.svg');
    background-size: contain;
    background-position:center;
    background-repeat: no-repeat;
  }