@import './components/variables';
@import './components/fonts';
@import './components/not-found';
@import './components/responsive';

* {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}


html {
    margin:0;
    padding:0;
    body {
        margin:0;
        padding:0;
        color: $default-text-color;
        font-family:$default-font-family;
        background:$default-background-color;
        font-size:$default-font-size;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
        a { text-decoration: none;
            &.link {
                text-decoration: none;
                color: inherit;
                font-size: inherit;
                transition: opacity .2s ease;
                font-size: 14px;
                font-weight: 500;
                &:focus,
                &:active,
                &:hover {
                    opacity: .7;
                }
                i {
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.text-center {
    text-align: center;
}

.logo {
    display: inline-block;
    width: 180px;
    height: 40px;
    img {
        width: 100%;
    }
}

.header {
    max-width: $header-container-size;
    padding: 30px 30px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin:0 auto;
    > div {
        a {
            &:first-child {
                margin-right: 30px;
            }
        }
    }
}

.page-title {

    h1 {
        font-size: 40px;
        margin:110px auto 0;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        padding:0 30px;
        span {
            background: #fff;
            padding: 1px 6px;
            color:$default-background-color;
        }
    }
    p {
        font-size:24px;
        text-align: center;
        margin: 40px 0 0;
        padding: 0 30px;
    }
}

.addict-radio {
    overflow: hidden;
}

.radio-stations {
    max-width: $body-container-size;
    margin:110px auto 0;
    padding:0 30px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .radio-station {
        width:275px;
        text-align: center;
        position: relative;
        margin: 0 0 100px;
        .player-effect {
            display: block;
            width: 275px;
            height: 275px;
            position: absolute;
            top: 25%;
            left: 0;
            filter: blur(25px);
            border-radius: 50%;
            animation: animate 2s linear infinite;
        }
        @keyframes animate {
            0% {
                transform: rotate(0deg)  scale(1);
            }
            50% {
                transform: rotate(180deg) scale(1.1);
            }
            100% {
                transform: rotate(360deg) scale(1);
            }
        }
        h3 {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            margin:0 0 25px;
            i {
                width:30px;
                height:30px;
                background: #fff;
                color: $default-background-color;
                border-radius: 50%;
                display: inline-block;
                font-size: 13px;
                line-height: 30px;
                text-align: center;
                margin-right: 15px;
            }
        }
        .cover {
            position: relative;
            overflow: hidden;
            border-radius: 27px;
            height:405px;
            perspective: 100px;
            background-color:$default-background-color;

            &.no-artist-image {
                &:after {
                    content:'';
                    position: absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    transition: transform 2s;
                    
                }
            }
            &:hover {
                &:after {
                    transform: scale(1.1) rotate(-5deg);
                }
            }
            h2,p {
                position: relative;
                z-index: 4;
                color:#fff;
                opacity: 0;
                padding: 0 20px;
                overflow: hidden;
            }
            h2{
                font-size:18px;
                text-transform: uppercase;
                margin:30px 0 5px;
                height: 22px;
            }
            p {
                font-size: 18px;
                font-weight: 400;
                margin:0 0 10px;
                height: 45px;
            }
            .gradient-overlay,
            .no-info-background {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
            }

            .gradient-overlay {
                z-index: 3;
            }
            .no-info-background {
                z-index: 4;
                padding:20px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3 {
                    font-size:24px;
                    font-weight: bold;
                    margin:0 0 5px;
                    display: block;
                    width: 100%;
                }
                span{
                    font-size: 22px;
                    display: block;
                    width: 100%;
                }
            }
            .radio-logo {
                position: absolute;
                top:50%;
                left: 50%;
                width: 100px;
                height: 100px;
                margin-top:-50px;
                margin-left:-50px;
                font-size: 100px;
                z-index:2;
                color: #fff;
            }
            .song-image {
                display: inline-block;
                width: 200px;
                height: 200px;
                z-index: 3;
                position: relative;
                opacity:0;
                transition: transform .15s, opacity .1s;
                transform-origin: 50% 50%;
                transform: scale(.8);
                .image {
                    position: absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    z-index:1;
                    background: #fff;
                    p {
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 400;
                        margin: 20px 0;
                        height: auto;
                        padding: 0 10px;
                        line-height: 23px;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 5%;
                    width: 90%;
                    height: 80%;
                    top: 20%;
                    z-index: 0;
                    box-shadow: 0 5px 10px rgba(0,0,0,.32);
                }
            }
        }
        .player {
            width: 210px;
            height: 100px;
            color: #000;
            border-radius: 15px;
            background: #fff;
            margin: -50px auto 0;
            position: relative;
            z-index: 5;
            padding: 15px 20px;
            i {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }
            .control {
                width:50px;
                height: 70px;
                font-size: 14px;
                line-height: 70px;
                border:1px solid #000;
                border-radius: 25px;
                margin:0 20px;
                transition: border-color .2s, background .2s;
            }
            .share {
                display: inline-block;
                position: relative;
                width:15px;
                height: 16px;
                .icon-share {
                    position: relative;
                    z-index: 1;
                    transition: color .2s ease;
                }
                .wrapper {
                    position: absolute;
                    top: -8px;
                    width: 106px;
                    left: -12px;
                    height: 38px;
                    background: #EDEAEA;
                    transition: transform .2s, opacity .1s ease;
                    border-radius: 25px;
                    opacity: 0;
                    padding: 4px 0 3px 35px;
                    text-align: left;
                    transform: scale(0);
                    i {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin: 0 5px 0 0;
                        overflow: hidden;
                        line-height: 30px;
                        background: transparent;
                        text-align: center;
                        transition: background .2s ease;
                        font-size:15px;
                        &:hover {
                            background: #fff;
                        }
                    }
                }

                &.active {
                    .wrapper{
                        opacity:1;
                        transform:scale(1);
                    }
                    .icon-share {
                        color:#9B9B9B;
                    }
                }
            }
        }
        &.is-playing {
            .line {
                top: -70%;
                height: 190%;
            }
            .cover {
                .song-image {
                    transform: scale(1);
                    opacity: 1;
                }
                h2,p {
                    opacity: 1;
                }
                &:after {
                    opacity: 0;
                }
            }
        }
        &.alternative {
            .cover {
                &:after {
                    background:url('../images/alternative.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .player-effect {
                background: linear-gradient(#61a9d2, #447591, #9edcff);
            }
        }
        &.rock {
            .cover {
                &:after {
                    background:url('../images/rock.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .player-effect {
                background: linear-gradient(#e55050, #923434, #ffa4a4);
            }
        }
        &.star {
            .cover {
                &:after {
                    background:url('../images/star.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .player-effect {
                background: linear-gradient(#ef87ce, #a35e8d, #ffafe6);
            }
        }
        &.lounge {
            .cover {
                &:after {
                    background:url('../images/lounge.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }  
            }
            .player-effect {
                background: linear-gradient(#8d84e6, #6660a7, #bfb9ff);
            }
        }
        &.error {
            display: block;
            margin: 0 auto;
            .no-info-background {
                z-index:3;
            }
        }
    }
}

.container-l {
    display: block;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 30px;
}
.container-s {
    display: block;
    max-width: 760px;
    margin: 0 auto;
    padding: 0 30px;
}

.container-xs {
    display: block;
    max-width: 530px;
    margin: 0 auto;
    padding: 0 30px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.how-to-listen {
    padding-bottom: 50px;
}


.privacy-policy {
    a { 
        
        text-decoration: underline;
        color: white;

        &.link {
            text-decoration: underline;
            color: white;
            font-size: inherit;
            transition: opacity .2s ease;
            font-size: 14px;
            font-weight: 500;
            &:focus,
            &:active,
            &:hover {
                opacity: .7;
            }
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
}

.ghack1{
    padding: 50px 0 0 0;
}

.devices {
    h2 {
        font-size:20px;
        text-transform: uppercase;
        margin: 100px 0 50px;
    }
    .app-link {
        width: 120px;
        text-decoration: none;
        color:$default-text-color;
        transition: opacity .2s ease;
        i {
            display: block;
            font-size:40px;
            margin: 0 0 10px;
        }
        span {
            text-decoration: underline;
            font-size:15px;
        }
        &:hover {
            opacity: .7;
            span {
                text-decoration: none;
            }
        }
    }
}